import products from '../../../sharedTypes/products';
import holderTypes from '../../../sharedTypes/holderTypes';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.SUBSCRIPTION_INTENTS,
  state: states.BANK_SELECTION,
  holderType: holderTypes.BUSINESS,
};
